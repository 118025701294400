<template>
  <div>
    <div>
      <el-row class="bgW">
      <el-col :span="20">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">

          <el-form-item label="标签组">
            <el-input
              v-model="search.tag_group_name"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>

        </el-form>
      </el-col>
      <el-col :span="4" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            v-loading="loading"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="序号" prop="id" label-class-name="market"/>
            <ElTableColumn label="标签名称" prop="name" />
            <ElTableColumn label="标签组名称" prop="tag_group_name" />
            <ElTableColumn label="状态" prop="status" label-class-name="market"/>
            <ElTableColumn label="标签数量" prop="count" label-class-name="market"/>

          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getTagCount } from "@/api/statistics";
class Search {
  tag_group_name="";
}
export default {
  name: 'customerTag',
  components: {

  },
  data () {
    return {
      list: [], // 数据展示
      loading:false,
      search: new Search(), // 检索条件
    }
  },
  mounted () {
    this.getList();

  },
  methods: {
    getList() {
      this.loading = true
      getTagCount({...this.search}).then(res => {
        this.list = res.data;
        this.loading = false
        });
      }
    },

}
</script>

<style lang="scss" scoped>
.hand{
  cursor:pointer;
}
.btnBox{
  text-align: right;
}
.market{
  background: red;
}
</style>
